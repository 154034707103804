"use strict";
document.addEventListener('DOMContentLoaded', () => {
    const accordionItems = document.querySelectorAll('.mainFaq__accordionItem');
    accordionItems.forEach((element) => {
        element.addEventListener('click', () => {
            const answer = element.querySelector('.mainFaq__accordionItemAnswer');
            const button = element.querySelector('.mainFaq__accordionItemButton');
            const svgPath = button?.querySelector('path');
            const isOpen = !answer.classList.contains('hidden');
            button?.classList.toggle('rotate-45');
            if (isOpen) {
                // Закрываем элемент
                answer.style.maxHeight = '0';
                svgPath?.setAttribute('fill', 'url(#paint0_linear_1767_576)');
                // Используем setTimeout для удаления hidden после анимации
                setTimeout(() => {
                    answer.classList.add('hidden');
                }, 300);
            }
            else {
                // Открываем элемент
                answer.classList.remove('hidden');
                answer.style.maxHeight = `${answer.scrollHeight}px`;
                svgPath?.setAttribute('fill', '#F34C04');
            }
        });
    });
});
